import React, { useState } from 'react';
import '../CSS/about.css';
import image1 from "../Images/logo.jpg"
import Navbar from './navbar';
import FooterComp from './footer';

const About = () => {
  const [showDetails, setShowDetails] = useState(false);

  const handleToggle = () => {
    setShowDetails(!showDetails);
  };

  return (
    <>
    <Navbar/>
    <div className="container about-us-container">
      <h2>About Us</h2>
      <img
            src={image1}
            alt="About Us"
            className="about-us-image"
          />
      <p></p>
      <p>
      At Lokenath Caterer and Travels, we believe in creating unforgettable experiences for our guests. 
      Our commitment goes beyond just providing a trip; we strive to offer a journey filled with small, meaningful services that enhance your overall travel experience.
      </p>
      <button className="toggle-button" onClick={handleToggle}>
        {showDetails ? 'Hide Details' : 'Show Details'}
      </button>
      {showDetails && (
        <div className="details-container">
         <h3> Culinary Delights:</h3>
Indulge your taste buds with our meticulously curated menu of super tasty local and international cuisines. Our chefs, passionate about creating culinary masterpieces, use the freshest ingredients to ensure each meal is a delight. From traditional dishes to gourmet treats, our dining experiences are designed to be a highlight of your journey.

              <h3> Complete Hospitality:</h3>
hospitality is not just a service; it's a promise. Our dedicated team is committed to making you feel at home, even when you're miles away. From the moment you step into our care, we ensure your comfort and satisfaction. Whether it's assisting with travel arrangements, providing personalized recommendations, or attending to your specific needs, our hospitality extends beyond expectation.

              <h3>Guided Visits to Unexplored Gems:</h3>
Discover the heart and soul of each destination with our expert-guided tours. Our knowledgeable guides bring history, culture, and local stories to life, providing you with insights that go beyond the surface. Explore hidden gems, off-the-beaten-path wonders, and iconic landmarks with guides who are passionate about showcasing the best of every location.

              <h3>Tailored Experiences:</h3>
We understand that each traveler is unique, and so are their preferences. Our commitment to personalization means that we tailor our services to suit your individual tastes and interests. Whether you're an adventure seeker, a history enthusiast, or a food connoisseur, our trips are designed to cater to your specific desires.

we don't just offer trips; we craft experiences that leave a lasting impression. Your journey with us is not just about the destination; it's about the moments, the flavors, and the people you encounter along the way. Thank you for choosing us to be a part of your travel story.

Safe travels!

Warm regards,
        </div>
      )}
    </div>
    <FooterComp />
    </>
  );
};

export default About;
