import './App.css';
import Card from './MyComponents/card';
import Navbar from './MyComponents/navbar';
import Banner from './MyComponents/banner';
import About from './MyComponents/about';
import Contact from './MyComponents/contact';
import Services from './MyComponents/services';
import FooterComp from './MyComponents/footer';
import Gallery from './MyComponents/gallery';
import { Route, Routes } from "react-router-dom";
import React, {useRef} from 'react';
import puri from "./Images/Puri.jpeg";
import daringbadi from "./Images/Daringbadi.jpeg";
import tripura from "./Images/Tripura.jpeg";
import darjeelingWithKalimpong from "./Images/DarjeelingWithKalimpong.jpeg";
import kalimpong from "./Images/Kalimpong.jpeg";
import northBengal from "./Images/NorthBengal.jpeg";
import sikkim from "./Images/Sikkim.jpeg";
import silkRoute from "./Images/SilkRoute.jpeg";
import dooars from "./Images/Dooars.jpeg";
import arunachal from "./Images/Arunachal.jpeg";
import shillong from "./Images/Shillong.jpeg";
import nepal from "./Images/Nepal.jpeg";
import bhutan from "./Images/Bhutan.jpeg";
import shimla from "./Images/Shimla.jpeg";
import lahaul from "./Images/Lahula.jpeg";
import himachal from "./Images/HimachalPradesh.jpeg";
import manali from "./Images/Manali.jpeg";
import kedarnath from "./Images/Kedarnath.jpeg";



function App() {

  const scrollRef1 = useRef(null);
  const scrollRef2 = useRef(null);
  const scrollToComponent = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  

  let Items = [
    {
      Name:"Puri Tour for 6 days",
      ShortDesc:"Konarak, dhabalgiri, Nandankanan",
      ImageLocation:puri,
      Date:"29/03 , 24/05 , 26/07 , 16/08 , 06/09/2024",
      Duration:"7,500 /-"
    },
    {
      Name:"Daringbadi with Gopalpur for 7 days",
      ShortDesc:"Hill view point, Doluri River, Putudi Water Fall, Gopalpur beach",
      ImageLocation:daringbadi,
      Date:"10/08 , 09/11, 17/12/2024",
      Duration:"10,000 /-"
    },
    {
      Name:"Tripura by Air for 8 days",
      ShortDesc:"Agartala, Jampui Hill, Unakoti, Neermahal, Karnala Sagar, Sipahijola, Udaipur and more",
      ImageLocation:tripura,
      Date:"03/04 , 05/05 , 11/11 , 02/12/2024",
      Duration:"18,000 /-"
    },
    {
      Name:"Darjeeling with Kalimpong for 7 days",
      ShortDesc:"Tiger Hill, Batasia Loop, Rock Garden, HMI, Zoo",
      ImageLocation:darjeelingWithKalimpong,
      Date:"09/03 , 17/03 , 20/04 , 11/05 , 25/05/2024",
      Duration:"10,000 /-"
    },
    {
      Name:"Kalimpong Tour for 7 days",
      ShortDesc:"Delo, Lava, Lolegaon, Rishop",
      ImageLocation:kalimpong,
      Date:"16/03 , 21/04 , 12/05 , 02/06/2024",
      Duration:"11000 /-"
    },
    {
      Name:"Off beat North Bengal for 7 days",
      ShortDesc:"Sittong, Ahal Dhara, Tea Garden, Namthing Pokhri, Tinchuley, Lepcha Jagat",
      ImageLocation:northBengal,
      Date:"02/03 , 24/10 , 24/11 , 25/12/2024",
      Duration:"11,000 /-"
    },
    {
      Name:"Sikkim Tour for 10 days",
      ShortDesc:"Pelling, Namchi, Ravangla, Gangtok, Changu Lake, Rumtake, Yumthang",
      ImageLocation:sikkim,
      Date:"02/03 , 03/04 , 12/05 , 02/10/2024",
      Duration:"21,000 /-"
    },
    {
      Name:"Silk Route Tour for 8 days",
      ShortDesc:"Sillery Gaon, Rishikhola, Aritar, Padamchen, Zuluk, Kupup Lake, Adi Baba Mandir",
      ImageLocation:silkRoute,
      Date:"09/03 , 06/04 , 19/05 , 20/10 , 04/11, 26/12/2024",
      Duration:"14,000 /-"
    },
    {
      Name:"Dooars Tour for 9 days",
      ShortDesc:"Gorumara, Murti, Jhalong, Bindu, Jaldapara, Jayanti, Cooch Behar",
      ImageLocation:dooars,
      Date:"02/03 , 05/05 , 23/05 , 22/10 , 15/11 , 27/12/2024",
      Duration:"14,000 /-"
    },
    {
      Name:"Arunachal tour for 12 days",
      ShortDesc:"Guwahati, Bhalukpong, Bomdilla, Dirang, Tawang, ",
      ImageLocation:arunachal,
      Date:"17/04 , 19/05 , 17/10/2024",
      Duration:"25,000 /-"
    },
    {
      Name:"Guwahati-Shillong-Kaziranga tour for 9 days",
      ShortDesc:"Kamakhya Temple, Balaji Temple, Basistha Temple, Cherrapunji, Dawki",
      ImageLocation:shillong,
      Date:"27/04 , 29/05 , 12/10 , 06/11/2024",
      Duration:"19,000 /-"
    },
    {
      Name:"Lumbini with Nepal for 12 days",
      ShortDesc:"Kathmandu, Pokhara, Chilwan, Lumbini",
      ImageLocation:nepal,
      Date:"17/04 , 22/05 , 12/06 , 16/10 , 11/12/2024",
      Duration:"24,000 /-"
    },
    {
      Name:"Bhutan tour for 9 days",
      ShortDesc:"Jaigaon, Phuentsholing, Thimphu, Paro, Punakha",
      ImageLocation:bhutan,
      Date:"02/03 , 03/04 , 08/05 , 26/05 , 12/06 , 23/10 , 24/11 , 28/12/2024",
      Duration:"27,000 /-"
    },
    {
      Name:"Kinnar with Shimla tour for 12 days",
      ShortDesc:"Shimla, Sarahan, Sangla, Chitkul, Kalpa",
      ImageLocation:shimla,
      Date:"23/05 , 19/09 , 09/10/2024",
      Duration:"22,000 /-"
    },
    {
      Name:"Kinnar-lahaul-Spiti tour for 16 days",
      ShortDesc:"Shimla, Sarahan, Sangla, Chitkul, Kalpa, Nako, Tabo, Kiza, Sissu, Manali",
      ImageLocation:lahaul,
      Date:"23/05 , 07/06 , 04/07 , 19/09/2024",
      Duration:"27,000 /-"
    },
    {
      Name:"Himachal Pradesh with Amritsar for 16 days",
      ShortDesc:"Shimla, Kulu, Manali, Rothang pass, Manikaran, Dharamsala, Jwalamukhi, Dalhousie, Khajjar",
      ImageLocation:himachal,
      Date:"21/04 , 26/05 , 09/10/2024",
      Duration:"26,000/-"
    },
    {
      Name:"Shimla Kullu Manali for 11 days",
      ShortDesc:"Shimla, Kulu, Manali Rothang Pass, Manikaran",
      ImageLocation:manali,
      Date:"10/03 , 21/04 , 26/05 , 09/10/2024",
      Duration:"19,000/-"
    },
    {
      Name:"Kedarnath-Badrinath-Tunganath for 14 days",
      ShortDesc:"Haridwar, Sitapur, Chopta, Ukhimath",
      ImageLocation:kedarnath,
      Date:"18/08 , 22/09/2024",
      Duration:"24,000 /-"
    },
  ]

  return (
    <div className="App">
      <Routes>
        <Route exact path='/' element ={[
        <Navbar key={1}/>,
        <Banner scrollToComponent={() => scrollToComponent(scrollRef1)} scrollToComponentContact={() => scrollToComponent(scrollRef2)} key={2}/>,
        <Card items={Items} ref={scrollRef1} key={3}/>,
        <Contact ref={scrollRef2} key={4}/>,<FooterComp key={5}/>]} />
      <Route path='/about' element={<About/>} />
      <Route path='/services' element={<Services/>} />
      <Route path='/gallery' element={<Gallery/>} />
      </Routes>
      </div>
  );
}

export default App;
