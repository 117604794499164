import React from 'react';
import '../CSS/gallery.css'; // Create this CSS file in the same folder as Gallery.js
import Navbar from './navbar';
import FooterComp from './footer';
import daringbadi from "../Images/Daringbadi.jpeg";
import tripura from "../Images/Tripura.jpeg";
import darjeelingWithKalimpong from "../Images/DarjeelingWithKalimpong.jpeg";
import kalimpong from "../Images/Kalimpong.jpeg";
import northBengal from "../Images/NorthBengal.jpeg";
import sikkim from "../Images/Sikkim.jpeg";
import silkRoute from "../Images/SilkRoute.jpeg";
import dooars from "../Images/Dooars.jpeg";
import arunachal from "../Images/Arunachal.jpeg";
import shillong from "../Images/Shillong.jpeg";
import nepal from "../Images/Nepal.jpeg";
import bhutan from "../Images/Bhutan.jpeg";
import shimla from "../Images/Shimla.jpeg";
import lahaul from "../Images/Lahula.jpeg";
import himachal from "../Images/HimachalPradesh.jpeg"
const images = [
  { src: kalimpong, alt: '1', description: 'Description for Image 1' },
  { src: sikkim, alt: '2', description: 'Description for Image 2' },
  { src: silkRoute, alt: '2', description: 'Description for Image 2' },
  { src: nepal, alt: '2', description: 'Description for Image 2' },
  { src: bhutan, alt: '2', description: 'Description for Image 2' },
  { src: himachal, alt: '2', description: 'Description for Image 2' },
  { src: lahaul, alt: '2', description: 'Description for Image 2' },
  { src: daringbadi, alt: '2', description: 'Description for Image 2' },
  { src: tripura, alt: '2', description: 'Description for Image 2' },
  { src: darjeelingWithKalimpong, alt: '2', description: 'Description for Image 2' },
  { src: northBengal, alt: '2', description: 'Description for Image 2' },
  { src: dooars, alt: '2', description: 'Description for Image 2' },
  { src: arunachal, alt: '2', description: 'Description for Image 2' },
  { src: shimla, alt: '2', description: 'Description for Image 2' },
  { src: kalimpong, alt: '2', description: 'Description for Image 2' },
  { src: shillong, alt: '2', description: 'Description for Image 2' },
  // Add more images here
];

const Gallery = () => {
  return (
    <>
    <Navbar/>
    <div className="gallery">
      <div className="gallery-items">
        {images.map((image, index) => (
          <div key={index} className="gallery-item">
            <img src={image.src} alt={image.alt} />
            <div className="description">{image.description}</div>
          </div>
        ))}
      </div>
    </div>
    <FooterComp/></>
  );
};

export default Gallery;
